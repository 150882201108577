import React from 'react';
import PropTypes from 'prop-types';

const Image = ({ src, alt, webp, className, ...props }) => {
  const fileExtension = src.substr(src.lastIndexOf('.') + 1);

  return (
    <picture>
      {webp ? <source type="image/webp" srcSet={webp} /> : null}
      <source type={`image/${fileExtension}`} srcSet={src} />
      <img src={src} alt={alt} loading="lazy" className={className} {...props} />
    </picture>
  );
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  webp: PropTypes.string,
  className: PropTypes.string
};

Image.defaultProps = {
  webp: null,
  alt: '',
  className: null
};

export default Image;
