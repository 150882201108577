import PropTypes from 'prop-types';
import React from 'react';

import './styles.scss';

export const TileSection1 = ({ src, desc, id, heading }) => (
  <div className="wrapper">
    <div style={heading ? {} : { marginBottom: '2%' }}><img src={src} className={`img-${id}`} alt="" /></div>
    {heading && (
      <h4 className="heading">
        {heading}
      </h4>
    )}
    <div className="desc">
      {desc}
    </div>
  </div>
);

TileSection1.propTypes = {
  src: PropTypes.string,
  desc: PropTypes.string,
  id: PropTypes.string,
  heading: PropTypes.string
};

TileSection1.defaultProps = {
  src: '',
  desc: '',
  id: '',
  heading: ''
};

// eslint-disable-next-line react/no-multi-comp
export const TileSection2 = ({ src, desc, id }) => (
  <div className="wrapper">
    <div><img src={src} className={`img-${id}`} alt="" /></div>
    <div className="desc">
      {desc}
    </div>
  </div>
);

TileSection2.propTypes = {
  src: PropTypes.string,
  desc: PropTypes.string,
  id: PropTypes.string
};

TileSection2.defaultProps = {
  src: '',
  desc: '',
  id: ''
};
