/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect } from 'react';
// import 'react-multi-carousel/lib/styles.css';
import { Link } from 'gatsby';

import { TileSection1 } from '../../components/tile';
import { Carousal } from '../../components/landing';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

import BannerSection from '../../components/banner';

import MockUp from '../../../static/home/png/mockup.png';
import MockUpWebp from '../../../static/home/webp/mockup.webp';
import sec1Img1 from '../../../static/home/svg/InvestinyourPassion.svg';
import sec1Img2 from '../../../static/home/svg/FavourableReturns.svg';
import sec1Img3 from '../../../static/home/svg/ExclusiveRewards.svg';
import sec2Img1 from '../../../static/home/svg/Food&Beverage.svg';
import sec2Img2 from '../../../static/home/svg/Direct-to-Consumer.svg';
import sec2Img3 from '../../../static/home/svg/ConsumerInternet.svg';
import rightArrow from '../../../static/home/svg/readRightArrow.svg';
import landingBanner from '../../../static/banners/home.svg';
import Styles from '../styles/landingPage.module.scss';
import '../../common-styles/styles.css';
import InvestorCard from '../../components/investorCard';
import { NewsCard } from '../../components/about';
import { newsList } from '../../mockdata/news';
import { videoList } from '../../mockdata/video';
import Image from '../../components/image';
import { openURL, saveURLParamToSession } from '../../utils';
import TestimonialCard from '../../components/testimonialCard';
import Slider from '../../components/slider';

const investors = [
  {
    img: `investors/surge.png`,
    mainText: 'Surge',
    subText: ''
  },
  {
    img: `investors/emvc.png`,
    mainText: 'EMVC',
    mainText2: 'Fintech Fund'
  },
  {
    img: `investors/tracxn.png`,
    mainText: 'Tracxn Labs',
    subText: ''
  },
  {
    img: `investors/9Unicorns.png`,
    mainText: '9Unicorns',
    subText: ''
  },
  {
    img: `investors/Alter.png`,
    mainText: 'Alter Global',
    subText: ''
  },
  {
    img: `investors/GMO.png`,
    mainText: 'GMO VenturePartners',
    subText: ''
  }
];

const HomePage = () => {
  useEffect(() => {
    const hashValue = window
      && window.location
      && window.location.hash
      && window.location.hash.slice(1);
    if (hashValue) {
      const section = window && window.document && window.document.getElementById(hashValue);
      const headerOffset = 30;
      const elementPosition = section && section.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  }, []);

  const handlePlatformRedirect = () => {
    openURL('patron-app_signup');
  };

  const handleBrandNavigate = () => {
    openURL('brand_app_signup');
  };

  useEffect(() => {
    saveURLParamToSession();
  }, []);

  // useEffect(() => {
  //   getViewHeight();
  //   window.addEventListener('resize', getViewHeight);
  //   // returned function will be called on component unmount
  //   return () => {
  //     window.removeEventListener('resize', getViewHeight);
  //   };
  // }, []);

  // const getViewHeight = () => {
  //   const LANDING = document.getElementById('landingCont');
  //   LANDING.style.height = `${window.outerHeight}px`;
  // };

  const handleReadMoreClick = () => {
    const section = window && window.document && window.document.getElementById('news-section');
    const headerOffset = 60;
    const elementPosition = section && section.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  };

  return (
    <Layout withCampaign={true}>
      <SEO />
      {/* landing tile */}
      <BannerSection
        image={landingBanner}
        heading="Flexible Growth Capital "
        subHeading="for Loved Brands"
        description="Raise ₹5 Lakhs - ₹20 Crores to turbocharge your revenues"
        buttons={[
          <Link key="get-funded" to="/brands" className="btn btnklub1">
            Get Funded
          </Link>,
          <Link key="invest-now" to="/patrons" className="btn btnklub2 ml3">
            Invest Now
          </Link>
        ]}
        isHome={true}
        blogLink={[
          <div className={Styles.blogLink}>
            <p>Klub to deploy ₹500 crore for local brands</p>
            <button type="button" onClick={handleReadMoreClick} className={`btn ${Styles.readMoreBtn}`}>
              Read More
              <img src={rightArrow} alt="readMore" />
            </button>
          </div>
        ]}
      />

      {/* Sectors we focus on section desktop */}
      <div
        className={`${Styles.sectionWrapper} ${Styles.dnone} ${Styles.dlgblock}`}
      >
        <div className={Styles.homeSection2}>
          <div className="container">
            <div className={Styles.row}>
              <div className={`${Styles.subSection21} ${Styles.subSectionStyle}`}>
                <div
                  className={`${Styles.tileWrapper} ${Styles.tileWrapperSection1}`}
                >
                  <div
                    className={`${Styles.tileSection1} ${Styles.tileSectionStyle}`}
                  >
                    <TileSection1
                      src={sec2Img2}
                      id="sec2-2"
                      desc="Fashion, beauty, and lifestyle"
                    />
                    <div className={Styles.tileSectionStyle1} />
                    <TileSection1
                      src={sec2Img1}
                      id="sec2-1"
                      desc="Wellness, FMCG, and F&B"
                    />
                  </div>
                  <div>
                    <TileSection1
                      src={sec2Img3}
                      id="sec2-2"
                      desc="Direct-to-consumer, consumer tech, and subscription businesses"
                    />
                  </div>
                </div>
              </div>
              <div className={Styles.subSection22}>
                <div className={Styles.sectionContentBox}>
                  <h3 className={`${Styles.dnone} ${Styles.dlgblock}`}>
                    UNLOCKING GROWTH THROUGH FINANCIAL INNOVATION
                  </h3>
                  <h5 className={`${Styles.dnone} ${Styles.dlgblock}`}>
                    We invest in things that make life joyful
                  </h5>
                  <ul className={Styles.list}>
                    <li>
                      Flexible repayments as a percentage of your brand’s
                      revenues with transparent fees
                    </li>
                    <li>
                      Build a network of patrons who can turbocharge your brand
                      value
                    </li>
                  </ul>
                  <div className={Styles.sectionButtons}>
                    <button
                      type="button"
                      onClick={handleBrandNavigate}
                      className="btn btnklub3"
                    >
                      Get Funded
                    </button>
                    <Link to="/brands" className="btn btnklub4 ml3">
                      Know More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Sectors we focus on section mobile */}
      <div
        className={`${Styles.dblock} ${Styles.dlgnone} ${Styles.sectionWrapper}`}
      >
        <div className={Styles.homeSection2}>
          <div className={Styles.row}>
            <div className="container">
              <div
                className={`${Styles.dblock} ${Styles.dlgnone} ${Styles.mb3}`}
              >
                <div className={Styles.sectionContentBox}>
                  <h3 className="">
                    UNLOCKING GROWTH THROUGH FINANCIAL INNOVATION
                  </h3>
                  <h5 className="">
                    We invest in things that make life joyful
                  </h5>
                </div>
              </div>
            </div>
            <div
              className={`${Styles.subSection21} container ${Styles.subSectionStyle1}`}
            >
              <div
                className={`${Styles.tileWrapper} ${Styles.tileWrapperSection1}`}
              >
                <div
                  className={`${Styles.tileSection1} ${Styles.tileSectionStyle}`}
                >
                  <TileSection1
                    src={sec2Img2}
                    id="sec2-2"
                    desc="Fashion, beauty, and lifestyle"
                  />
                  <TileSection1
                    src={sec2Img1}
                    id="sec2-1"
                    desc="Wellness, FMCG, and F&B"
                  />
                </div>
                <div>
                  <TileSection1
                    src={sec2Img3}
                    id="sec2-2"
                    desc="Direct-to-consumer, consumer tech, and subscription businesses"
                  />
                </div>
              </div>
            </div>
            <div className={`${Styles.subSection22} container`}>
              <div className={Styles.sectionContentBox}>
                <ul className={Styles.list}>
                  <li>
                    Flexible repayments as a percentage of your brand’s revenues
                    or flat fees
                  </li>
                  <li>
                    Access to an exclusive network of Klub patrons who can
                    turbocharge your brand value
                  </li>
                </ul>
                <div className={Styles.sectionButtons}>
                  <button
                    type="button"
                    onClick={handleBrandNavigate}
                    className="btn btnklub3"
                  >
                    Get Funded
                  </button>
                  <Link to="/brands" className="btn btnklub4 ml3">
                    Know More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* invest in brands on section desktop */}
      <div
        className={`${Styles.sectionWrapper} ${Styles.dnone} ${Styles.dlgblock}`}
      >
        <div className={Styles.homeSection2}>
          <div className="container">
            <div className={Styles.row}>
              <div className={Styles.subSection22}>
                <div className={Styles.sectionContentBox}>
                  <h3 className={`${Styles.dnone} ${Styles.dlgblock}`}>
                    INVEST IN BRANDS YOU LOVE
                  </h3>
                  <h5 className={`${Styles.dnone} ${Styles.dlgblock}`}>
                    Be a part of an exclusive invite-only community
                  </h5>
                  <ul className={Styles.list}>
                    <li>
                      Enter the world of alternative investments with a
                      hassle-free investment experience
                    </li>
                    <li>
                      Robust financial assessment, operational risk mitigation,
                      and legal frameworks to protect your investments
                    </li>
                    <li>
                      Get above-market returns while building your favorite
                      brands
                    </li>
                  </ul>
                  <div className={Styles.sectionButtons}>
                    <button
                      type="button"
                      onClick={handlePlatformRedirect}
                      className="btn btnklub3"
                    >
                      Invest Now
                    </button>
                    <Link to="/patrons" className="btn btnklub4 ml3">
                      Know More
                    </Link>
                  </div>
                </div>
              </div>
              <div className={`${Styles.subSection32} ${Styles.subSectionStyle}`}>
                <div
                  className={`${Styles.tileWrapper} ${Styles.tileWrapperSection1}`}
                >
                  <div
                    className={`${Styles.tileSection1} ${Styles.tileSectionStyle}`}
                  >
                    <TileSection1
                      src={sec1Img1}
                      desc="Invest in your favorite brands"
                      id="sec1-1"
                    />
                    <div className={Styles.tileSectionStyle1} />
                    <TileSection1
                      src={sec1Img2}
                      desc="Get attractive returns"
                      id="sec1-2"
                    />
                  </div>
                  <div>
                    <TileSection1
                      src={sec1Img3}
                      desc="Enjoy exclusive rewards, discounts, and more!"
                      id="sec1-3"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* invest in brands on section mobile */}
      <div
        className={`${Styles.dblock} ${Styles.dlgnone} ${Styles.sectionWrapper}`}
      >
        <div className={Styles.homeSection2}>
          <div className={Styles.row}>
            <div className="container">
              <div
                className={`${Styles.dblock} ${Styles.dlgnone} ${Styles.mb3}`}
              >
                <div className={Styles.sectionContentBox}>
                  <h3 className="">INVEST IN BRANDS YOU LOVE</h3>
                  <h5 className="">
                    Be a part of an exclusive invite-only community
                  </h5>
                </div>
              </div>
            </div>
            <div
              className={`${Styles.subSection32} container ${Styles.subSectionStyle1}`}
            >
              <div
                className={`${Styles.tileWrapper} ${Styles.tileWrapperSection1}`}
              >
                <div
                  className={`${Styles.tileSection1} ${Styles.tileSectionStyle}`}
                >
                  <TileSection1
                    src={sec1Img1}
                    desc="Invest in your favorite brands"
                    id="sec1-1"
                  />
                  <TileSection1
                    src={sec1Img2}
                    desc="Get attractive returns"
                    id="sec1-2"
                  />
                </div>
                <div>
                  <TileSection1
                    src={sec1Img3}
                    desc="Enjoy exclusive rewards, discounts, and more!"
                    id="sec1-3"
                  />
                </div>
              </div>
            </div>
            <div className={`${Styles.subSection22} container`}>
              <div className={Styles.sectionContentBox}>
                <ul className={Styles.list}>
                  <li>
                    Enter the world of alternative investments with a
                    hassle-free investment experience
                  </li>
                  <li>
                    Robust financial assessment, operational risk mitigation,
                    and legal frameworks to protect your investments
                  </li>
                  <li>
                    Get above-market returns while building your favorite brands
                  </li>
                </ul>
                <div className={Styles.sectionButtons}>
                  <button
                    type="button"
                    onClick={handlePlatformRedirect}
                    className="btn btnklub3"
                  >
                    Invest in Brands
                  </button>
                  <Link to="/patrons" className="btn btnklub4 ml3">
                    Know More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Video Testimonial */}
      <div className={`${Styles.sectionWrapper}`}>
        <div className={Styles.homeSection2}>
          <div>
            <div className={`${Styles.sectionContentBox} container`}>
              <h3>TESTIMONIALS</h3>
              <h5>Hear from our patrons & brands</h5>
            </div>
            <div className={`${Styles.videocardSection} container`}>
              <Slider
                margin={false}
                settings={{
                  slidesToShow:
                    typeof window !== 'undefined' && window?.innerWidth > 768
                      ? 2
                      : 1,
                  slidesToScroll:
                    typeof window !== 'undefined' && window?.innerWidth > 768
                      ? 2
                      : 1
                }}
                name="video-testimonial-about"
              >
                {videoList.featuredSlides.map((item) => (
                  <TestimonialCard
                    key={item.id}
                    img={item.imgSrc}
                    head={item.head}
                    name={item.name}
                    profile={item.profile}
                    url={item.url}
                    dp={item.dp}
                    isHomepage={true}
                    isBrand={item.isBrand}
                  />
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      {/* About klub section */}
      <div className={Styles.sectionWrapper}>
        <div className={Styles.homeSection4}>
          <div className="container">
            <div className={Styles.row}>
              {/* <div className={`${Styles.dlgblock}  ${Styles.dnone}`} style={{ width: '50%' }}> */}
              <div
                className={`abtKlub ${Styles.dlgblock} ${Styles.dnone} ${Styles.subsection41Container}`}
              >
                <div className={Styles.subsection41}>
                  <Image src={MockUp} webp={MockUpWebp} alt="Platform" />
                </div>
              </div>
              <div className={Styles.collg6}>
                <div className={Styles.sectionContentBox}>
                  <h3>ABOUT KLUB</h3>
                  <h1>
                    Fintech platform providing growth capital for loved brands
                    through Revenue Based Financing
                  </h1>
                  <p className={Styles.section5Content1}>
                    Klub was founded with the vision of revolutionizing growth
                    capital for Asia’s most loved brands. We are a platform for
                    investors to support local brands in their community while
                    enjoying above-market returns.
                  </p>
                  {/* <p className={Styles.section5Content2}>We are a team of passionate professionals, with a mission to unlock growth capital for Asia&apos;s most loved brands.</p> */}
                  <p className={Styles.section5Content2}>
                    Klub’s investment platform utilizes financial innovation,
                    community engagement, and deep data-driven analytics to
                    provide skin-in-the-game growth capital to entrepreneurs of
                    much-loved brands across sectors.
                  </p>
                  <div
                    className={`${Styles.sectionButtons} ${Styles.mt4}  ${Styles.ml0}`}
                  >
                    {/* <Link to="https://klubworks.freshteam.com/jobs/" target="_blank" className="btn btnklub3">Join the Team</Link> */}
                    <Link to="/about" target="_blank" className="btn btnklub3">
                      Meet the Team
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Our Investors section */}
      <div className={Styles.homeSection5}>
        <div className="container">
          <h5>Our Investors</h5>
          <div className={Styles.investorsWrapper}>
            {investors.map((investor) => (
              <InvestorCard
                key={investor.mainText}
                img={investor.img}
                imgWp={investor.imgWp}
                mainText={investor.mainText}
                mainText2={investor.mainText2 ? investor.mainText2 : ''}
                subText={investor.subText ? investor.subText : ''}
                designation={investor.designation ? investor.designation : ''}
                company={investor.company ? investor.company : ''}
                color="#FFFFFF"
                opacity={0.5}
              />
            ))}
          </div>
          <div className={Styles.section5Button}>
            <Link to="/about#ourInvestors" className="btn btnklub1">
              See More
            </Link>
          </div>
        </div>
      </div>

      {/* Testimonials section */}
      <div className={Styles.homeSection6} id="news-section">
        {/* <div className="container">
          <h3>TESTIMONIALS</h3>
          <h1 className={Styles.webView}>
            What our partners say about us
          </h1>
          <h1 className={Styles.mobileView}>What our partners say about us</h1>
        </div>
        <div className={Styles.carousalWrapper}>
          <Carousal
            margin={true}
            name="testimonials"
            autoScroll={false}
          >
            {data.map((item) => (
              <TestimonialCard
                content={item.content}
                partnerImage={item.partnerImage}
                partnerName={item.partnerName}
                partnerType={item.partnerType}
                designation={item.designation}
                companyName={item.companyName}
              />
            ))}
          </Carousal>
        </div> */}
        <div className="container">
          {/* <h3>IN THE NEWS</h3>
          <div className={Styles.newsContainer}>
            {newsList.map((newsItem) => (
              <div className={`${Styles.newsItem}`}>
                <Link to={newsItem.url} className={Styles.logoWrapper} target="_blank">
                  <img src={newsItem.img} alt="" className={`${newsItem.id}`} />
                </Link>
              </div>
            ))}
          </div> */}
          {
            // <ul className="nav nav-fill newsUL mt-3 mt-sm-4">
            //   {newsList.map((logos) => (
            //     <li className="nav-item">
            //       <a className="nav-link" href={logos.url} target="_blank">
            //         <img src={logos.img} alt="" className={`${logos.id}`} />
            //       </Link>
            //     </li>
            //   ))}
            // </ul>
          }

          <h5>Klub in the news</h5>
        </div>
        <div className={Styles.klubNews}>
          <Carousal margin={true} autoScroll={false} name="testimonials-about">
            {newsList.map((item) => (
              <NewsCard
                logoFilename={item.logoFilename}
                thumbFilename={item.thumbFilename}
                key={item.id}
                desc={item.des}
                date={item.date}
                link={item.link}
              />
            ))}
          </Carousal>
        </div>
      </div>
    </Layout>
  );
};

export default HomePage;
